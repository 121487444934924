<template>
  <nav class="router">
    <a v-if="currentRouteName == 'pl'" href="#modal" data-target="#quick-access-menu" class="quick__access d-none d-md-flex flex-column flex-md-row justify-content-end align-items-end align-items-md-center
            pe-3 text-decoration-none position-relative py-md-3">
      <img src="./assets/img/loupe.png" width="19" alt="scroll" class="scroll__down--img mb-1 mb-md-0 me-md-2"/>
      <div class="text-end text-uppercase c-blue f-middle">
        Quick Access
      </div>
    </a>
  </nav>
  <router-view/>
</template>

<script>
    // @ is an alias to /src
    //import global from '../assets/js/app.js';

    export default {
        name: 'app',
        mounted() {
            //global.init();
        },
        computed: {
            currentRouteName() {
                return this.$route.name;
            }
        }
    }
</script>
