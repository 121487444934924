<template>
  <div>
    <h1 class="item__titre c-blue-dark f-titre-h1 text-uppercase ff-anton mb-0" :style="'font-size: '+bloc.size + 'px; line-height: '+bloc.lineHeight + 'px;'" :class="[checkSize(bloc.size)]" v-if="checkType(bloc.type, 'title_h1')" :data-animation="bloc.animation">
      <div v-html="bloc.text"></div>
    </h1>

    <h1 class="item__titre item__titre--full text-uppercase ff-anton" :style="'font-size: '+bloc.size + 'px;'" :class="bloc.style" v-if="checkType(bloc.type, 'title_full')" :data-animation="bloc.animation">
      <div v-html="bloc.text" class="py-5"></div>
    </h1>

    <h2 class="item__titre c-blue-dark f-titre-h2 ff-anton" :class="bloc.size" :style="'font-size: '+bloc.size + 'px;'" v-if="checkType(bloc.type, 'title_h2')" :data-animation="bloc.animation">
      <div v-html="bloc.text" :class="[checkSize(bloc.size)]" class="my-3"></div>
    </h2>
  </div>
</template>

<script>
export default {
  props: ['bloc'],
    mounted() {
        this.responsivePosition();
        window.addEventListener("resize", this.responsivePosition);
    },
    unmounted() {
        window.removeEventListener("resize", this.responsivePosition);
    },
  methods: {
      checkType(type, check) {
          if(check === type) {
              return true;
          }
          return false;
      },
      checkSize(size) {
          if(this.$props.bloc.extraClass != null) {
              return size+" "+this.$props.bloc.extraClass;
          } else {
              if(size === "big") {
                  return size+" pt-4 pb-5";
              }
              return size+" pt-4 pb-4";
          }
      },
      responsivePosition() {
          let position = "";

          if(this.$props.bloc.position) {
              position = this.$props.bloc.position;

              this.$el.querySelector(".item__titre").classList.add(position);
          }

          if(this.$props.bloc.responsive && this.$props.bloc.responsive.position && window.innerWidth > 768) {
              position = this.$props.bloc.responsive.position;
          }

          if(position && position != "") {

              this.$el.querySelectorAll(".item__titre").forEach(function(title) {
                  title.style.textAlign = position;

                  if(position == "center") {
                      title.style.marginLeft = "auto";
                      title.style.marginRight = "auto";
                  }
              });
          }

      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

  .item__titre {
    overflow: hidden;
    &.normal {
      font-size: $fs-titre-h1-normal;
      line-height: $lh-titre-h1-normal;
    }

    &.little {
      font-size: $fs-titre-h2-little;
      line-height: $lh-titre-h2-little;
    }

    &.right-wrong {
      font-size: $fs-titre-h1-big;
      line-height: $lh-titre-h1-big;
     transform: translateX(30px);

      small {
        font-size: $fs-titre-h2-big;
        line-height: $lh-titre-h2-big;
        margin-right: 75px;
      }
    }

    &.f-titre-h1 {
      &.large {
        font-size: $fs-titre-h1-big;
        line-height: $lh-titre-h1-big;
      }
    }

    &.left {
      text-align: left;
      margin-right: auto;
    }

    span {
      color: $c-blue;
    }

    i {
      font-style: normal;
      -webkit-text-stroke: 1px $c-white;
      color: transparent;
    }
    u {
      text-decoration: none!important;
      color: $c-white;
    }

    small {
      font-size: $fs-titre-h2;
      line-height: $lh-titre-h2;
    }

    &--full {
      font-size: $fs-titre-full;

      &.border {
        -webkit-text-stroke: 2px $c-blue;
        color: transparent;
        border: none!important;
      }

      &.shadow {
        box-shadow: none!important;

        & > div {
          color: #f4bba1;
          position: relative;
          z-index: 1;
          display: inline-block;

          &:before {
            content: '';
            box-shadow: 0px 0px 30px 40px rgba(181,75,49,0.9);
            position: absolute;
            left: 20%;
            right: 20%;
            top: 50%;
            transform: translateY(-50%);
            height: 0;
            z-index: -1;
          }
        }

        &-little {
          @media (max-width: 767px) {
            font-size: 67px;
          }
        }
      }

      &.fill {
        color: $c-blue-dark;
      }
    }

    &.f-titre-h2 {
      text-align: left;
      max-width: 85%;

      @media (min-width: 768px) {
        max-width: 100%;
      }

      &.right {
        text-align: right;
        margin-left: auto;
      }

      &.center {
        text-align: center;
        margin: 0 auto;
        max-width: 100%;
      }

      &.big {
        font-size: $fs-titre-h2-big;
        line-height: $lh-titre-h2-big;
      }

      &.little {
        font-size: $fs-titre-h2-little;
        line-height: $lh-titre-h2-little;
      }

    }
  }

  [data-animation="fixed"] {
    &.fixed {
      position: fixed;
      top: 0;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1000000;
      margin-left: 0!important;
      margin-right: 0!important;
      transition: opacity 300ms ease;
      transition-delay: 0.1s;
      opacity: 1!important;
    }

    &.opacity-0 {
      opacity: 0!important;
    }
  }

  [data-animation="fixed2"] {
    transition: opacity 300ms ease;
    transition-delay: 0.1s;
    width: 100%;
    opacity: 0;

    @media (min-width: 768px) {
      opacity: 1;
    }

    &.opacity {
      opacity: 1;
    }

    &.fixed {
      @media (min-width: 768px) {
        position: relative!important;
        top: auto!important;
        left: auto!important;
      }
    }
  }

  .fixed {
    position: fixed;
  }

  .bloc_fixed .bloc--text__image {
    .item__image.opacity-animation {
      img {
        transition: opacity 300ms ease;
      }
    }
  }

</style>
