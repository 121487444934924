<template>
  <!-- OneTrust Cookies Settings button start -->
  <div class="d-flex w-100 justify-content-center">
    <button id="ot-sdk-btn" class="ot-sdk-show-settings item__btn text-uppercase ff-anton mt-0">Cookies</button>
  </div>
  <!-- OneTrust Cookies Settings button end -->
</template>

<script>
    export default {
        props: ['lang'],
        methods: {
            insertAfter(referenceNode, newNode) {
                referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
            }
        },
        mounted() {
            var ID = "5fa46ab5-8043-4b3e-a6cc-7e78a6172a1e";
            var ref = document.querySelector('[href="favicon.png"]');

            if(document.querySelector('script[data-domain-script]') !== null) {
                document.querySelector('script[data-domain-script]').remove();
                document.querySelector('#onetrust-consent-sdk').remove();

                const cookielawLang = document.createElement("script");

                cookielawLang.setAttribute("src", "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js");
                cookielawLang.setAttribute("data-language", this.$props.lang);
                cookielawLang.setAttribute("charset", "UTF-8");
                cookielawLang.setAttribute("data-domain-script", ID);

                ref = document.querySelector(".cookie-scripts");
                this.insertAfter(ref, cookielawLang);

            } else {
                const cookielaw = document.createElement("script");
                cookielaw.setAttribute("src", "https://cdn.cookielaw.org/consent/"+ID+"/OtAutoBlock.js");
                cookielaw.classList.add('cookie-scripts');
                document.head.insertBefore(cookielaw, ref);

                const cookielawLang = document.createElement("script");

                cookielawLang.setAttribute("src", "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js");
                cookielawLang.setAttribute("data-language", this.$props.lang);
                cookielawLang.setAttribute("charset", "UTF-8");
                cookielawLang.setAttribute("data-domain-script", ID);
                this.insertAfter(cookielaw, cookielawLang);

                const cookielawFunction = document.createElement("script");
                cookielawFunction.innerHTML = 'function OptanonWrapper() {}';
                this.insertAfter(cookielawLang, cookielawFunction);
            }
        },
    }
</script>
