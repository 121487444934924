<template>
  <div class="home pb-3 pb-md-4">

    <div class="quick__access--desktop"></div>

    <section v-for="zone in page" :key="zone.type" :id="'section__'+zone.type">

      <div v-if="checkType(zone.type, 'intro')">

        <img src="../assets/img/pierre-fabre.png" width="135" alt="pierre fabre" class="my-2"/>

        <div v-for="bloc in zone.blocs" :key="bloc.type" class="container--custom">

          <ImageComponents :bloc="bloc" v-if="checkType(bloc.type, 'image')"/>

          <TexteComponents :bloc="bloc" :zone="zone.type" v-if="checkType(bloc.type, 'text')"/>

          <div v-if="checkType(bloc.type, 'quick-access')" class="mt-4 mb-3">
            <a href="#modal" data-target="#quick-access-menu" class="quick__access quick__access--mobile d-flex d-md-none flex-column flex-md-row justify-content-end align-items-end align-items-md-center
            pe-3 text-decoration-none position-relative py-md-3">
              <img src="../assets/img/loupe.png" width="19" alt="scroll" class="scroll__down--img mb-1 mb-md-0 me-md-2"/>
              <div class="text-end text-uppercase c-blue f-middle" v-html="bloc.text"></div>
            </a>

            <ModalComponents :bloc="bloc.modal" id="quick-access-menu" v-if="bloc.modal"/>
          </div>

          <div v-if="checkType(bloc.type, 'btn-scroll')" class="pb-5 pt-md-5">
            <figure class="scroll__down mb-5 mt-md-5">
              <div class="scroll__down--texte text-uppercase c-blue f-middle mb-3" v-html="bloc.text"></div>
              <img src="../assets/img/btn-arrow-blue-down.png" width="47" alt="scroll" class="scroll__down--img"/>
            </figure>
          </div>

          <TitreComponents :bloc="bloc" v-if="checkType(bloc.type, 'title_h1')"/>
          
        </div>
      </div>

      <div v-if="checkType(zone.type, 'content')" class="container__colon container__colon--first" id="start__diving">
        <img src="../assets/img/tete-colon-fond.png" alt="tete du colon" width="1411" class="container__colon--top"/>

        <figure class="position-relative m-0 bulles">
          <img src="../assets/img/plongeur.png" width="316" alt="plongeur du colon" class="container__colon--plongeur plongeur"/>

          <img src="../assets/img/bulle-left.png" width="37" alt="bulles" class="bulle bulle-left"/>
          <img src="../assets/img/bulle-right.png" width="77" alt="bulles du colon" class="bulle bulle-right"/>
        </figure>

        <div v-for="bloc in zone.blocs" :key="bloc.type" :class="bloc.type">

          <MasqueComponents :bloc="bloc" v-if="checkType(bloc.type, 'diving_mask')"/>

          <div class="container--custom">
            <ChapterComponents :bloc="bloc" v-if="checkType(bloc.type, 'chapter')"/>

            <ImageComponents :bloc="bloc" v-if="checkType(bloc.type, 'image')"/>

            <TexteComponents :bloc="bloc" :zone="zone.type" v-if="checkType(bloc.type, 'text')"/>

            <TitreComponents :bloc="bloc" v-if="checkType(bloc.type, 'title_h1')"/>
            <TitreComponents :bloc="bloc" v-if="checkType(bloc.type, 'title_h2')"/>
            <TitreComponents :bloc="bloc" v-if="checkType(bloc.type, 'title_full')"/>

            <ButtonComponents :bloc="bloc" v-if="checkType(bloc.type, 'button')"/>
            <SpacerComponents :bloc="bloc" v-if="checkType(bloc.type, 'spacer')"/>

            <CarouselComponents :bloc="bloc" v-if="checkType(bloc.type, 'slider_text')"/>

            <CapsuleComponents :bloc="bloc" v-if="checkType(bloc.type, 'capsules')"/>

            <div v-if="checkType(bloc.type, 'text_image')" class="bloc--text__image row" :class="bloc.position">
              <div :class="bloc.text.col">
                <div v-for="b in bloc.text.blocs" :key="b.type" class="">
                  <TexteComponents :bloc="b" :zone="zone.type" v-if="checkType(b.type, 'text')"/>

                  <TitreComponents :bloc="b" v-if="checkType(b.type, 'title_h1')"/>
                  <TitreComponents :bloc="b" v-if="checkType(b.type, 'title_h2')"/>
                  <TitreComponents :bloc="b" v-if="checkType(b.type, 'title_full')"/>
                </div>
              </div>
              <div :class="bloc.image.col">
                <div v-for="b in bloc.image.blocs" :key="b.type" class="">
                  <ImageComponents :bloc="b" v-if="checkType(b.type, 'image')"/>
                </div>
              </div>
            </div>

            <div class="container--fixed" v-if="checkType(bloc.type, 'bloc_fixed')">
              <div v-for="element in bloc.elements" :key="element.type">

                <TitreComponents :bloc="element" v-if="checkType(element.type, 'title_h1')"/>
                <TitreComponents :bloc="element" v-if="checkType(element.type, 'title_h2')"/>
                <TitreComponents :bloc="element" v-if="checkType(element.type, 'title_full')"/>

                <div v-if="checkType(element.type, 'text_image')" class="bloc--text__image row" :class="element.position">
                  <div :class="element.text.col">
                    <div v-for="b in element.text.blocs" :key="b.type" class="">
                      <TexteComponents :bloc="b" :zone="zone.type" v-if="checkType(b.type, 'text')"/>

                      <TitreComponents :bloc="b" v-if="checkType(b.type, 'title_h1')"/>
                      <TitreComponents :bloc="b" v-if="checkType(b.type, 'title_h2')"/>
                      <TitreComponents :bloc="b" v-if="checkType(b.type, 'title_full')"/>
                    </div>
                  </div>
                  <div :class="element.image.col">
                    <div v-for="b in element.image.blocs" :key="b.type" class="">
                      <ImageComponents :bloc="b" v-if="checkType(b.type, 'image')"/>
                    </div>
                  </div>
                </div>

                <ImageComponents :bloc="element" v-if="checkType(element.type, 'image')"/>
                <SpacerComponents :bloc="element" v-if="checkType(element.type, 'spacer')"/>
                <TexteComponents :bloc="element" :zone="element.type" v-if="checkType(element.type, 'text')"/>

              </div>
            </div>

          </div>

          <div v-if="checkType(bloc.type, 'banner')" class="py-5">
            <div class="banner--item c-white ff-anton text-center px-4 py-3">
              <div v-html="bloc.text"></div>
            </div>
          </div>

        </div>

      </div>

        <div v-if="checkType(zone.type, 'end')" class="container__colon container__colon--last pb-5">
          <div v-for="bloc in zone.blocs" :key="bloc.type">

            <figure class="position-relative m-0 container__colon--last--figure" v-if="checkType(bloc.type, 'end_colon')">
              <img src="../assets/img/fin-colon-fond.png" alt="bas du colon" width="1411" class="container__colon--bottom"/>
              <img src="../assets/img/plongeur-end.png" width="316" alt="plongeur du colon" class="container__colon--plongeur--end plongeur-end"/>
            </figure>

            <div class="container--custom">
              <ImageComponents :bloc="bloc" v-if="checkType(bloc.type, 'image')"/>

              <TexteComponents :bloc="bloc" :zone="zone.type" v-if="checkType(bloc.type, 'text')"/>

              <TitreComponents :bloc="bloc" v-if="checkType(bloc.type, 'title_h1')"/>
              <TitreComponents :bloc="bloc" v-if="checkType(bloc.type, 'title_h2')"/>
              <TitreComponents :bloc="bloc" v-if="checkType(bloc.type, 'title_full')"/>

              <ButtonComponents :bloc="bloc" v-if="checkType(bloc.type, 'button')"/>
              <SpacerComponents :bloc="bloc" v-if="checkType(bloc.type, 'spacer')"/>

            <div v-if="checkType(bloc.type, 'title_button')" class="bloc--title__button py-2 py-md-4">
              <div v-for="b in bloc.blocs" :key="b.type">
                <TitreComponents :bloc="b" v-if="checkType(b.type, 'title_h1')"/>
                <TitreComponents :bloc="b" v-if="checkType(b.type, 'title_h2')"/>
                <TitreComponents :bloc="b" v-if="checkType(b.type, 'title_full')"/>

                <div v-if="checkType(b.type, 'buttons')" class="d-md-flex justify-content-md-center align-items-md-center">
                  <div v-for="button in b.blocs" :key="button.type" class="px-md-3">
                    <ButtonComponents :bloc="button" v-if="checkType(button.type, 'button')"/>
                  </div>
                </div>

                <TexteComponents :bloc="b" :zone="zone.type" v-if="checkType(b.type, 'text')"/>
              </div>
            </div>
          </div>

              <div v-if="checkType(bloc.type, 'btn-scroll-up')">
                <figure class="scroll__up">
                  <img src="../assets/img/btn-arrow-blue-up.png" width="47" alt="scroll" class="scroll__up--img"/>
                  <div class="scroll__up--texte text-uppercase c-blue f-middle mt-3" v-html="bloc.text"></div>
                </figure>
              </div>

            <div v-if="checkType(bloc.type, 'rs')" class="rs d-md-flex justify-content-md-center align-items-md-center py-4">
              <div class="c-white text-uppercase f-normal mb-1 mb-md-0 me-md-2">{{bloc.text}}</div>
              <div class="d-flex justify-content-center align-items-end">
                <a :href="r.link" target="_blank" v-for="r in bloc.rs" :key="r.type" class="rs--items mx-2">
                  <img :src="getImgUrl(r.type+'.png')" :alt="r.type"/>
                </a>
              </div>
            </div>
          </div>

        </div>

      <div v-if="checkType(zone.type, 'outro')" class="py-4">
        <div v-for="bloc in zone.blocs" :key="bloc.type" class="container--custom">

          <ImageComponents :bloc="bloc" v-if="checkType(bloc.type, 'image')"/>

          <TexteComponents :bloc="bloc" :zone="zone.type" v-if="checkType(bloc.type, 'text')"/>

          <TitreComponents :bloc="bloc" v-if="checkType(bloc.type, 'title_h1')"/>
          <TitreComponents :bloc="bloc" v-if="checkType(bloc.type, 'title_h2')"/>
          <TitreComponents :bloc="bloc" v-if="checkType(bloc.type, 'title_full')"/>

          <ButtonComponents :bloc="bloc" v-if="checkType(bloc.type, 'button')"/>
          <SpacerComponents :bloc="bloc" v-if="checkType(bloc.type, 'spacer')"/>

          <div v-if="checkType(bloc.type, 'menu')" class="menus d-flex justify-content-center">
            <div v-for="(link, index) in bloc.links" :key="link.type" class="menus--items d-flex">
              <a :href="link.link" target="_blank" class="c-blue">{{link.text}}</a>
              <div class="menus--items--separator mx-2" v-if="index != (bloc.links.length-1)">-</div>
            </div>
          </div>

        </div>
      </div>

  </section>

  </div>

  <div class="ligne position-absolute"></div>
</template>

<script>

import { useMotion } from "@vueuse/motion";
import $ from 'jquery'
import global from '../assets/js/app'

export default {
  name: 'page-content',
  props: ['page'],
  methods: {
      checkType(type, check) {
          if(check === type) {
              return true;
          }
          return false;
      },
      getImgUrl(img) {
          return require('../assets/img/'+img)
      }
  },
    mounted() {

        const refs = [
            $('.plongeur').get(0),
            $('.bulle-left').get(0),
            $('.bulle-right').get(0),
        ];

        refs.forEach((ref, index) => {
            const { variant } = useMotion(ref, {
                initial: {
                    y: 100,
                    opacity: 0,
                },
                visibleOnce: {
                    y: 0,
                    opacity: 1,
                    transition: {
                        type: "spring",
                        stiffness: 200,
                        damping: 30,
                        delay: index * 200,
                        onComplete: () => {
                              variant.value = "levitate";
                        },
                    },
                },
                levitate: {
                    y: 15,
                    transition: {
                        duration: 1500,
                        repeat: Infinity,
                        ease: "easeInOut",
                        repeatType: "mirror",
                    },
                },
            });
        });

        document.querySelectorAll("#section__content .item__image:not(.no-gsap), #plongeur_full").forEach((ref, index) => {
            if(!global.isInModal(ref)) {
                const { variant } = useMotion(ref, {
                    initial: {
                        opacity: 0,
                    },
                    visible: {
                        y: 0,
                        opacity: 1,
                        transition: {
                            type: "spring",
                            stiffness: (index%2 == 0 ? "100" : "50"),
                            damping: (index%2 == 0 ? "10" : "20"),
                            delay: (index%2 == 0 ? "400" : "300"),
                            onComplete: () => {
                                if(variant) {
                                    variant.value = "levitate";
                                }
                            },
                        },
                    },
                    levitate: {
                        y: 15,
                        transition: {
                            duration: 1500,
                            repeat: Infinity,
                            ease: "easeInOut",
                            repeatType: "mirror",
                        },
                    },
                });
            }

        });

        document.querySelectorAll(".item__texte--overlay").forEach((ref, index) => {
            if(!global.isInModal(ref)) {
                useMotion(ref, {
                    initial: {
                        x: -100,
                        opacity: 0,
                    },
                    visibleOnce: {
                        x: 0,
                        opacity: 1,
                        transition: {
                            type: "spring",
                            stiffness: 300,
                            damping: 20,
                            delay: (index%2 == 0 ? "300" : "200"),
                        },
                    }
                });
            }

        });

        document.querySelectorAll(".item__titre:not([data-animation='fixed2']):not([data-animation='fixed']) > div").forEach((ref, index) => {
            if(!global.isInModal(ref)) {
                useMotion(ref, {
                    initial: {
                        y: '200%',
                        opacity: 0
                    },
                    visibleOnce: {
                        y: 0,
                        opacity: 1,
                        transition: {
                            type: "spring",
                            stiffness: 300,
                            damping:25,
                            delay: (index%2 == 0 ? "500" : "600"),
                        },
                    }
                });
            }

        });


        document.querySelectorAll(".banner .banner--item span").forEach((ref, index) => {
            if(!global.isInModal(ref)) {
                useMotion(ref, {
                    initial: {
                        x: (index%2 == 0 ? 150 : -150),
                        opacity: 0
                    },
                    visible: {
                        x: 0,
                        opacity: 1,
                        transition: {
                            type: "spring",
                            stiffness: 300,
                            damping:25,
                            delay: (index%2 == 0 ? "300" : "400"),
                        },
                    }
                });
            }

        });

    },
}
</script>


<style lang="scss">
  .home {
    margin-top: -20px;
    //margin-left: auto;
    //margin-right: auto;
    //max-width: 1411px;

    @media (min-width: 768px) {
      margin-top: -50px;
    }
  }

  .container__colon {
    background-image: url("../assets/img/colon.png");
    background-position: center;
    background-size: 100%;
    background-repeat: repeat;
    position: relative;

    &--first {
      margin-top: 400px;
      padding-bottom: 350px;
      background-color: #e6946e;
    }

    &--last {
      margin-top: -315px;

      background-image: none;

      .container--custom {
        max-width: 100%;
        padding: 0;
      }

      #plongeur_full {
        @media (max-width: 768px) {
          margin-bottom: -170px;
        }
      }
    }

    &--top {
      position: absolute;
      top: -27px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      pointer-events: none;

      @media (min-width: 1411px) {
       width: 100%;
      }
    }
    &--bottom {
      position: absolute;
      bottom: 230px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      pointer-events: none;

      @media (min-width: 1411px) {
        width: 100%;
      }
    }
    &--plongeur {
      margin-top: -352px;
      z-index: 2;
      position: relative;
      margin-bottom: 50px;

      &--end {
        z-index: 2;
        position: relative;
        margin: 50px auto;
      }
    }
  }

  .bulles {
    display: inline-block;

    .bulle {
      &-left {
        position: absolute;
        left: 20%;
        top: 27%;
        margin-top: -352px;
        z-index: 1;
      }

      &-right {
        position: absolute;
        right: 20%;
        top: 2%;
        margin-top: -352px;
        z-index: 1;
      }
    }
  }

  .scroll__down {
    &--texte {
      animation: scroll_anim ease-in-out 1.8s infinite alternate;
      z-index: -1;
      position: relative;
    }

    &--img {
      z-index: 2;
      position: relative;

      &:hover {
        cursor: pointer;
      }
    }

    @keyframes scroll_anim {
      0% {
        transform: rotate(-90deg) translateX(25px);
      }
      60% {
        transform: rotate(-90deg) translateX(30px);
      }
      100% {
        transform: rotate(-90deg) translateX(23px);
      }
    }
  }
  .scroll__up {
    z-index: 2;
    position: relative;

    &:hover {
      cursor: pointer;
    }
  }

  .quick__access {
    margin-left: auto;

    @media (max-width: 768px) {
      max-width: 80px;
    }


    &:after {
      content: '';
      background-color: $c-blue-dark;
      width: 8px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;

    }
  }

  .rs {
    background-color: $c-blue;

    &--items {
      img {
        width: 26px;
        height: 22px;
        object-fit: contain;
      }
    }
  }

  .banner--item {
    background-color: $c-blue-dark;
    transform: rotate(-8deg);
    -webkit-transform-origin: 50%  51%;
    position: relative;
    font-size: $fs-normal;
    line-height: $lh-normal;
    
    @media (min-width: 768px) {
      font-size: $fs-titre-h1;
      line-height: $lh-titre-h1;

      & > div {
        max-width: 768px;
        margin: 0 auto;
      }

    }

    &:after {
      content: "";
      position: absolute;
      left: -50%;
      top: 0;
      right: -50%;
      bottom: 0;
      background-color: $c-blue-dark;
      z-index: -1;
    }

    span {
      display: inline-block;
    }
  }

  .bloc {
    &--text__image {
      @media (min-width: 768px) {
        padding-bottom: 75px;
        padding-top: 75px;

        &.normal {
          .item__texte--right {
            width: 100%;
          }
        }

        &.reverse {
          flex-direction: row-reverse;
          justify-content: flex-end;
        }
      }
    }
    &--title__button {
      background-color: $c-blue-dark;

      @media (max-width: 768px) {
        padding: 0 45px;
      }

      .item__titre {
        color: white!important;
      }
    }
  }

</style>