import { createRouter, createWebHistory } from 'vue-router'
import PLView from '../views/PLView.vue'

const routes = [
  {
    path: '/',
    name: 'pl',
    component: PLView
  },
  {
      path: '/zastrzezenia-prawne',
      name: 'personal-data-policy',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/PLPolicyView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
